<template>
  <div>
    <OrganismsGsdBar :vertical="vertical" />

    <molecules-hero-header
      :image-config="{
        img: `${APP_ASSET_BASEURL}edge/static/images/hero/life_hero_images_v2_1x.webp`,
        class: 'max-w-full w-[28rem] lg:h-[23.44rem]',
        dimensions: {
          width: 448,
          height: 380
        }
      }"
      :buttons="[
        {
          theme: 'primary',
          text: 'COMPARE NOW',
          url: verticalData.formPage
        }
      ]"
      title-class="text-2xl md:text-[3rem] lg:text-[3rem] md:!leading-[3.5rem] lg:!leading-[3.5rem]"
      title-tag="p"
      sub-title-class="mt-6"
      img-alt="Get more from your cover. Compare Life Insurance today"
    >
      <template #header>
        Get more from your cover.
        <br />
        <span class="font-bold">Compare Life Insurance</span>
        <br />
        today
      </template>

      <template #sub-header>
        We have
        <img
          class="inline w-24"
          :src="expertEaseImg"
          alt="Expert ease"
          width="96"
          height="16.69"
        />
        in finding cost-effective peace of mind. Compare <br />
        estimates from ten of Australia’s leading life insurers.
      </template>
    </molecules-hero-header>

    <MoleculesTrustpilot
      :layout="!devices.isDesktop.value ? 'compact' : 'default'"
      class="py-8 border border-solid border-gray-350 min-h-[5.875rem] bg-white"
    />
    <molecules-home-company-comparison
      :assets-base-url="`${APP_ASSET_BASEURL}edge/static/images/company-logos/`"
      :companies="PARTNER_ITEMS"
      class="!bg-white"
      theme="v2"
      more-button-url="/life-insurance/companies/"
      is-center
      is-carousel
      image-lazy-load
    >
      <template #header>
        Our <strong>Life Insurance</strong> Partners
      </template>
    </molecules-home-company-comparison>

    <molecules-home-great-deal
      :cards="GREAT_DEAL_ITEMS"
      :col-count="4"
      :button-config="{
        class: '!text-sm'
      }"
      card-class="p-6"
      card-wrapper-max-width="w-wrapper-lg"
      class="!bg-blue-5"
      image-lazy-load
      header-title-class="md:!text-3.5xl"
      title-class="!text-base"
      description-class="!text-sm"
    >
      <template #header>
        What is <strong class="font-bold">life insurance?</strong>
      </template>

      <template #sub-header>
        Life insurance is an umbrella term to describe several policies that can be claimed on. <br class="hidden lg:block" />The following policies are all types of life insurance:
      </template>
    </molecules-home-great-deal>

    <molecules-home-table-tabs
      class="bg-white"
      :tabs="[
        {
          label: 'Retail life insurance',
          key: 'retail-life-insurance'
        },
        {
          label: 'Default life in super',
          key: 'default-life-in-super'
        }
      ]"
      :list="WHY_GET_LIFE_INSURANCE"
      :title="{
        'Retail life insurance': '<strong>Life Insurance VS Default Life in Super</strong>',
        'Default life in super': '<strong>Life Insurance VS Default Life in Super</strong>'
      }"
      :sub-header="{
        'Retail life insurance': `Did you know you might have default life insurance via your superannuation? <br class='hidden lg:block' />There are benefits to having retail life insurance & default life in super. <br class='hidden lg:block' />Here are some of the differences:`,
        'Default life in super': `Did you know you might have default life insurance via your superannuation? <br class='hidden lg:block' />There are benefits to having retail life insurance & default life in super. <br class='hidden lg:block' />Here are some of the differences:`
      }"
    />

    <molecules-home-saving-plan
      :verticals="SAVING_PLAN_ITEMS"
      class="bg-gray-10"
      layout="two-columns"
      card-title-class="md:!text-2xl"
      card-description-class="md:!text-base"
      image-lazy-load
    >
      <template #header>
        Why life insurance <strong>is important?</strong>
      </template>
    </molecules-home-saving-plan>

    <AtomsHomeComparisonTableTabs
      :layout="devices.isMobile.value ? 'two-table' : 'table'"
      title="How much do <strong>life insurance premiums cost?</strong>"
      sub-title="*Costs are based on policies for an average, non-smoker, white collar worker on an $80k salary <br class='hidden md:block' />with no pre-existing conditions"
      :costs="COMPARISON_TABLE_DATA"
      :tabs="['$1 Million Cover', '$2 Million Cover']"
      bg-class="bg-gray-350"
      wrapper-class="!max-w-wrapper-md"
      footer="<span class='italic mr-1'>Quotes correct as of </span> 23/09/23 - subject to expiry"
    />

    <atoms-site-links :items="SITE_LINKS" class="bg-gray-10">
      <template #header>
        Learn more about <strong>life insurance</strong>
      </template>
    </atoms-site-links>

    <div class="bg-white px-4 lg:px-0 pt-[2.125rem] pb-20 lg:pt-20">
      <div class="max-w-wrapper-md mx-auto">
        <p class="text-2xl md:text-3.5xl text-center leading-10 md:leading-[3rem]">
          How we’ve <strong class="font-extrabold">helped Australians</strong> like you.
        </p>

        <MoleculesTrustpilotReviews
          class="mt-8"
          :tags="vertical.replace(/\s/g, '-')"
        />
      </div>
    </div>

    <molecules-faq
      id="glossary"
      title="A glossary of life insurance terms"
      :list="GLOSSSARY_LIST"
      :multiple-collapse="false"
      class="bg-gray-10"
      hide-schema
    >
      life insurance can be complicated. Below are some of the key terms you may see when comparing life insurance
    </molecules-faq>

    <molecules-faq
      id="faq"
      title="Life Insurance FAQs"
      title-tag="h1"
      :list="FAQ_ITEMS"
      :multiple-collapse="false"
    >
      Common questions about comparing life insurance. Here are some of the most frequently asked questions we receive about life insurance:
    </molecules-faq>

    <AtomsAuthorBlock
      name="Matthew Lang"
      description="Matthew Lang is the general manager of life insurance at Compare Club. Matthew leads a team of dedicated professionals who are passionate about helping individuals and families make informed decisions about their life insurance needs. Whether it's finding the right coverage for your specific circumstances, comparing policies, or optimizing your existing policy, Matthew and his team are here to provide expert guidance and support."
      job-title="General Manager of Life Insurance"
      image="/images/authors/mathew-lang.svg"
    />

    <molecules-home-guide-tiles
      :guides="GUIDES_ITEMS"
      :action-button-url="VERTICALS[vertical].guidesUrl"
      class="!py-20"
      image-lazy-load
    >
      <template #header>
        All your <strong>Life Insurance questions answered</strong><br class="hidden md:block" />
        with our in depth guides
      </template>
    </molecules-home-guide-tiles>

    <molecules-home-footer-info>
      <template #title>
        Things you should know
      </template>

      <template #description>
        <p>
          Advizer Pty Ltd ABN 83 168 993 333 of 222 Pitt Street Sydney, trading as Life Insurance Comparison, owns and operates LifeinsuranceComparison.com.au and its associated website.
          Advizer Pty Ltd is an authorised representative of Alternative Media Pty Ltd (AFSL 486326) and fully owned subsidary of Compare Club Group.
        </p>
      </template>
    </molecules-home-footer-info>
  </div>
</template>

<script setup>
import {
  GREAT_DEAL_ITEMS,
  SAVING_PLAN_ITEMS,
  GUIDES_ITEMS,
  FAQ_ITEMS,
  SITE_LINKS,
  GLOSSSARY_LIST,
  COMPARISON_TABLE_DATA,
  WHY_GET_LIFE_INSURANCE,
  PARTNER_ITEMS
} from '~/constants/pages/life-insurance'

const { devices } = deviceChecker()
const $route = useRoute()

const vertical = VERTICAL.LIFE_INSURANCE
const verticalData = VERTICALS[vertical]

defineOptions({
  name: 'LifeInsurance'
})

generateMeta({
  title: 'Life Insurance Comparison Australia | Compare Club',
  ogTitle: 'Life Insurance Comparison Australia | Compare Club',

  description: 'Compare life insurance policies and save. Get a free quote from a licensed life insurer broker, all in one place. Click here to compare',
  ogDescription: 'Compare life insurance policies and save. Get a free quote from a licensed life insurer broker, all in one place.',

  ogUrl: 'https://compareclub.com.au/life-insurance/',
  ogType: 'product'
}, pageIsFunnel($route))
 
useJsonld(() => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Life insurance comparison',
    image: 'https://compareclub.com.au/_ipx/s_190x30/images/cc-logo-new.svg',
    url: 'https://compareclub.com.au/life-insurance/',
    description: 'At Compare Club, our expertise is in making it easy to find life insurance policies that covers you and your loved ones without breaking the bank.',
    brand: {
      '@type': 'Brand',
      name: 'Compare Club'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      bestRating: '5',
      worstRating: '1',
      ratingCount: '3028'
    }
  }
})

</script>
